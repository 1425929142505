import React from "react"

import LegalLayout from "../../views/legal/LegalLayout";
import TermsPage from "../../views/legal/TermsPage";

const Terms = ({ location, pageContext: { locale } }) => (
    <LegalLayout location={location} locale={locale}>
        <TermsPage/>
    </LegalLayout>
);

export default Terms;
