import React, { Fragment } from "react";

import { withTranslate } from "../../components/translate";
import ScrollToTopOnMount from "../../components/common/ScrollToTopOnMount";
import Link from "../../components/common/Link";
import SEO from "../../components/SEO";

const TermsPage = withTranslate(function ({ translate }) {
    return (
        <Fragment>
            <SEO
                title={translate("legal.terms.head.title")}
                description={translate("legal.terms.head.description")}
                path="/legal/terms"
            />
            <ScrollToTopOnMount />
            <header className="container py-3 mt-4 mb-5">
                <div className="intro mt-3">
                    <h1>
                        {translate("legal.terms.title")}
                    </h1>
                    <p className="h2-sub mb-0">
                        {translate("legal.terms.subtitle")}
                    </p>
                </div>
            </header>
            <div className="container mb-5 pb-5">

                <p>
                    Ti preghiamo di leggere attentamente le presenti condizioni generali ("Condizioni") prima di utilizzare il sito <Link href="/">dockington.com</Link> e il servizio <a href="https://app.dockington.com">Dockington</a> (insieme, o individualmente, il "Servizio") gestito da CheekSoft Oy ("CheekSoft", "noi", "nostro" o "nostri").
                    L'accesso e l'utilizzo del Servizio sono subordinati all'accettazione e al rispetto delle presenti Condizioni. Le presenti Condizioni si applicano a tutti i visitatori, utenti e altri che desiderano accedere o utilizzare il Servizio.
                    Accedendo o utilizzando il Servizio, accetti di essere vincolato al rispetto di queste Condizioni. Se non sei d'accordo con qualsiasi parte delle Condizioni, non hai il permesso di utilizzare il Servizio.
                </p>

                <h2 id="communications">1. Comunicazioni</h2>
                <p>
                Quando Ti registri per ottenere un account del Servizio, accetti di ricevere email e materiale di marketing e altre informazioni che potremmo inviarti. Tuttavia, puoi scegliere di non ricevere queste comunicazioni da noi seguendo il link di cancellazione nelle e-mail che inviamo, o contattandoci a <a href="mailto:privacy@cheeksoft.com">privacy@cheeksoft.com</a>.
                </p>

                <h2 id="subscriptions">2. Abbonamenti</h2>
                <p>
                    Il servizio Dockington è acquistabile in forma di abbonamento ("Abbonamento"). L’addebito avverrà in anticipo su base ricorrente e periodica ("Ciclo di Fatturazione"). I Cicli di Fatturazione sono stabiliti su base mensile o annuale.
                    Alla fine di ogni Ciclo di Fatturazione, l'Abbonamento si rinnoverà automaticamente alle stesse Condizioni, a meno che Tu o CheekSoft lo annulli. È possibile annullare il rinnovo dell'Abbonamento dalla pagina di gestione dell'account o contattando il <a href="mailto:info@cheeksoft.com">supporto di CheekSoft</a>.
                </p>
                <p>
                    Per elaborare il pagamento del Tuo Abbonamento è necessario un metodo di pagamento valido tra quelli indicati nella pagina principale nella sezione “Domande più frequenti”.  Inoltre, è necessario fornire a CheekSoft informazioni di fatturazione accurate e complete, tra cui nome e cognome, indirizzo, informazioni di contatto e un metodo di pagamento valido. Inserendo tali informazioni di pagamento, autorizzi CheekSoft ad addebitare a tali strumenti di pagamento tutte le spese dell’Abbonamento sostenute attraverso il Tuo account.
                </p>
                <p>
                    Se l’addebito automatico non dovesse andare a buon fine per qualsiasi motivo, CheekSoft proverà ad addebitare l’importo una o più volte. Se il pagamento dovesse continuare a fallire, l'Abbonamento sarà annullato.
                </p>
                <p>
                    Il pagamento con bonifico bancario è offerto solo per Abbonamenti annuali. Una volta scelto il bonifico bancario come metodo di pagamento, riceverai una fattura da pagare con scadenza a 2 settimane. Se non riceveremo il pagamento entro la scadenza, il Tuo account verrà sospeso fino a quando non verrà ricevuto un pagamento valido.
                </p>

                <h2 id="free-trial">3. Prova gratuita</h2>
                <p>
                    CheekSoft può, a sua esclusiva discrezione, offrire un Abbonamento con una prova gratuita per un periodo di tempo limitato ("Prova Gratuita").
                    In qualsiasi momento e senza preavviso, CheekSoft si riserva il diritto di (i) modificare i termini e le condizioni dell'offerta di Prova Gratuita, o (ii) annullare tale offerta di Prova Gratuita.
                </p>

                <h2 id="fee-changes">4. Modifiche prezzi</h2>
                <p>
                    A nostra esclusiva discrezione e in qualsiasi momento, possiamo modificare i prezzi degli Abbonamenti. Qualsiasi modifica ai prezzi degli Abbonamenti diventerà effettiva alla fine del Ciclo di Fatturazione corrente. Ti avviseremo con ragionevole anticipo di qualsiasi modifica alle tariffe dell’Abbonamento per darTi l'opportunità di interrompere l'abbonamento prima che tale modifica diventi effettiva. L'uso continuato del Servizio dopo l'entrata in vigore della modifica della tariffa dell’Abbonamento costituisce il Tuo consenso a pagare il nuovo importo dell’Abbonamento.
                </p>

                <h2 id="refunds">5. Rimborsi</h2>
                <p>
                    L’importo dell’Abbonamento non è rimborsabile. CheekSoft può prendere in considerazione le richieste di rimborso in base a circostanze eccezionali, caso per caso, e la loro concessione è a nostra esclusiva discrezione.
                </p>

                <h2 id="exporting-your-data">6. Esportare i tuoi dati</h2>
                <p>
                    Puoi richiedere l'esportazione completa dei Tuoi dati contattandoci a <a href="mailto:info@cheeksoft.com">info@cheeksoft.com</a>. Prenderemo in considerazione e soddisferemo la tua richiesta non appena tecnicamente fattibile.
                </p>

                <h2 id="content">7. Contenuto</h2>
                <p>
                    Il Nostro Servizio Ti consente di archiviare dati e informazioni su prodotti, Te, la Tua azienda e altri. Sei responsabile del Contenuto che aggiungi sul Servizio, inclusa la sua legalità, affidabilità e adeguatezza.
                    <br/>
                    Mantieni ogni diritto su qualsiasi Contenuto inserito nel Servizio e sei responsabile della protezione di tali diritti. Non ci assumiamo alcuna responsabilità per i Contenuti che archivi nel Servizio. CheekSoft ha il diritto, ma non l'obbligo, di monitorare tutti i Contenuti aggiunti dagli utenti.
                    <br/>
                    Inoltre, il Contenuto all’interno del Nostro Servizio che non è stato originariamente aggiunto da Te è di proprietà di CheekSoft. Non Ti è consentito distribuire, modificare, trasmettere, riutilizzare, scaricare, ripubblicare, copiare o utilizzare tali Contenuti, né per intero né in parte, per scopi commerciali o per guadagno personale, senza un esplicito permesso scritto da parte nostra.
                    <br/>
                    Puoi leggere le nostre pratiche in merito a sicurezza e privacy e i Tuoi diritti nella nostra <Link to="/legal/privacy">Privacy Policy</Link>.
                    <br/>
                    Qualsiasi Contenuto archiviato nel Servizio verrà archiviato indefinitamente, a meno che non venga eliminato esplicitamente. Questo processo è descritto sotto "Interruzione del servizio".
                </p>

                <h2 id="accounts">8. Account</h2>
                <p>
                    Sei responsabile del mantenimento della riservatezza del Tuo account e password. Accetti di assumerTi la responsabilità per qualsiasi attività o azione che si verifica sotto il Tuo account e/o password. Se dovessi venire a conoscenza di qualsiasi violazione della sicurezza o dell'uso non autorizzato del Tuo account, dovrai comunicarcelo immediatamente.
                </p>

                <h2 id="intellectual-property">9. Proprietà intellettuale</h2>
                <p>
                    Salvo diversa indicazione, il Sito, i Servizi e tutti i Contenuti (esclusi i Contenuti forniti dagli utenti) dei Servizi, inclusi, a titolo esemplificativo, disegn, testi, grafica, immagini, informazioni, dati, software, file audio e altri file (collettivamente, i "Materiali CheekSoft") sono di proprietà di CheekSoft e sono protetti dalle leggi internazionali sul copyright.
                </p>
                <p>
                    Non Ti è consentito rivendere, alterare, modificare, rimuovere, copiare, pubblicare o trasferire alcun elemento che incorpori i diritti di proprietà intellettuale (compresi, ma non limitati a loghi, immagini o dati). Non Ti è consentito utilizzare alcuno strumento di data mining o automazione per la raccolta o l'estrazione di dati dei Materiali CheekSoft.
                </p>

                <h2 id="links">10. Link ad altri siti</h2>
                <p>
                    Il Nostro Servizio può contenere collegamenti a siti o servizi di terzi che non sono di proprietà o controllati da CheekSoft. Non abbiamo alcun controllo e non ci assumiamo alcuna responsabilità per il Contenuto, le politiche sulla privacy o le pratiche di tali siti o servizi di terzi. Non garantiamo le offerte di nessuna di queste entità o individui o dei loro siti.
                    Riconosci e accetti che CheekSoft non sarà responsabile, direttamente o indirettamente, per alcun danno o perdita causati o presunti essere causati da o in relazione all'uso di tali Contenuti, beni o servizi disponibili su o attraverso tali siti o servizi di terzi.
                    Ti consigliamo vivamente di leggere i termini e le condizioni e le politiche sulla privacy di tutti i siti o servizi di terzi che visiti.
                </p>

                <h2 id="termination">11. Interruzione del servizio</h2>
                <p>
                    In caso di violazione delle nostre Condizioni, potremmo interrompere o sospendere il Tuo account e bloccare l'accesso al Servizio immediatamente, senza preavviso né responsabilità da parte nostra.
                <p>
                </p>
                    Ci riserviamo il diritto di chiudere il Tuo account sulla base di motivi tecnici, economici o legali, con un preavviso di 90 giorni. In tal caso, Ti forniremo un'opzione per esportare tutti i Tuoi dati. Se hai pagato un abbonamento annuale, Ti offriremo un rimborso per il periodo rimanente del Tuo Ciclo di Fatturazione dopo la chiusura del Tuo account.
                </p>
                <p>
                    Se desideri interrompere il Tuo account, puoi farlo dalle impostazioni del Tuo account o contattando il Nostro servizio clienti. Il Tuo abbonamento terminerà alla fine del Tuo Ciclo di Fatturazione corrente e non Ti verrà più addebitato alcun costo.
                </p>
                <p>
                    Dopo 90 giorni dalla interruzione o sospensione del Tuo account, il Contenuto verrà eliminato dai Nostri server e non potrà più essere recuperato. Riconosci che è Tua responsabilità copiare o richiedere l'esportazione di tutto il Contenuto del servizio prima che il Tuo account venga chiuso.
                </p>

                <h2 id="indemnification">12. Risarcimento</h2>
                <p>
                Accetti di mantenere indenne CheekSoft e i suoi licenziatari, dipendenti, agenti, funzionari e direttori, da e contro qualsiasi pretesa, danno, obbligo, perdita, responsabilità, costo o debito e spese (comprese, a titolo esemplificativo ma non esaustivo, le spese legali), derivanti da a) uso e accesso al Servizio da parte Tua o di qualsiasi persona che utilizza il Tuo account e password; b) violazione delle presente Condizioni, o c) Contenuto caricato nel Servizio.
                </p>

                <h2 id="liability">13. Limitazioni di responsabilità</h2>
                <p>
                    Il sito, i Materiali CheekSoft e il Servizio sono forniti "così come sono" e "come disponibili" senza garanzie di alcun tipo, espresse o implicite. In nessun caso la società, i suoi dipendenti o agenti saranno ritenuti responsabili nei Tuoi confronti o di terzi per danni diretti, indiretti, accidentali, consequenziali, speciali, punitivi o esemplari. Né la società sarà ritenuta responsabile nei Tuoi confronti o di terzi per lesioni, perdite o profitti mancati derivanti dall'uso o dall'uso improprio del Servizio o da qualsiasi informazione, materiale o dati forniti tramite il Servizio.
                </p>
                <p>
                    CheekSoft non garantisce che a) il Servizio funzionerà ininterrotto, sicuro o disponibile in qualsiasi momento o luogo particolare; b) i risultati derivanti dall'utilizzo del Servizio soddisferanno le Tue esigenze. Facciamo del Nostro meglio per mantenere la massima qualità, sicurezza e disponibilità del Servizio, ma riconosciamo che nessun Servizio può essere garantito sicuro al 100% o senza errori. Se trovi errori, difetti e/o potenziali problemi di sicurezza, Ti preghiamo di contattarci immediatamente a <a href="mailto:privacy@cheeksoft.com">privacy@cheeksoft.com</a>.
                </p>

                <h2 id="changes">14. Aggiornamenti</h2>
                <p>
                    Ci riserviamo il diritto di modificare le Condizioni o qualsiasi Policy del Servizio in qualsiasi momento e a nostra esclusiva discrezione. Eventuali modifiche diventano effettive al momento della pubblicazione delle Condizioni aggiornate sul sito. L'uso del Servizio dopo la pubblicazione delle modifiche alle Condizioni costituisce l'accettazione di tali modifiche. Se sei un utente del Nostro Servizio, Ti contatteremo via email per informarTi delle modifiche apportate alle nostre Condizioni.
                    <br/>
                    Se non sei d’accordo con le nuove Condizioni, non Ti è più consentito utilizzare il Servizio e il Tuo account verrà chiuso.
                </p>
            </div>
        </Fragment>
    )
});

export default TermsPage;
